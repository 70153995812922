<template>
  <p v-if="loading">Loading...</p>
  <div class="announcement-preview"  v-else>
      <h4>{{ announcement.title }}</h4>
      <h6>Created: {{ announcement.createdDate }}</h6>
      <h6>Posted: {{ announcement.postedDate }}</h6>
      <div v-html="announcement.text"></div>
  </div>
</template>

<script lang="ts">
import { Announcement } from "@/store/Announcement";
import { computed, onMounted, toRefs } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    id: String,
  },
  setup(props:any) {
    const { id: announcementId } = toRefs(props)
    const store = useStore();
    const announcements = computed(() => store.state.announcements);
    console.log(announcements.value.map((v: Announcement) => v.id));
    const announcement = computed(() => announcements.value.filter((v: Announcement) => v.id == announcementId.value)[0]);

    const fetchThings = async () => {
      store.dispatch("getAnnouncement", announcementId.value);
    };

    onMounted(fetchThings);

    const loading = computed(() => store.state.loadingAnnouncements || announcement.value == undefined);

    return {
      announcements,
      announcement,
      loading
    };
  },
};
</script>

<style scoped>
.announcement-preview {
  padding: 1.5rem;
}
</style>
