
import { Announcement } from "@/store/Announcement";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const announcements = computed(() => store.state.announcements);

    return {
      announcements,
    };
  },
};
