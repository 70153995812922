
import { Announcement } from "@/store/Announcement";
import { computed, onMounted, toRefs } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    id: String,
  },
  setup(props:any) {
    const { id: announcementId } = toRefs(props)
    const store = useStore();
    const announcements = computed(() => store.state.announcements);
    console.log(announcements.value.map((v: Announcement) => v.id));
    const announcement = computed(() => announcements.value.filter((v: Announcement) => v.id == announcementId.value)[0]);

    const fetchThings = async () => {
      store.dispatch("getAnnouncement", announcementId.value);
    };

    onMounted(fetchThings);

    const loading = computed(() => store.state.loadingAnnouncements || announcement.value == undefined);

    return {
      announcements,
      announcement,
      loading
    };
  },
};
