<template>
  <div>
    <router-link :to="{ name: 'CreateAnnouncement' }"
      >Create Announcement</router-link
    >
    <h2>Existing Announcements</h2>
    <div
      class="announcement-preview"
      v-for="announcement in announcements"
      :key="announcement.id"
    >
      <h4>{{ announcement.title }}</h4>
      <h6>Created: {{ announcement.createdDate }}</h6>
      <h6>Posted: {{ announcement.postedDate }}</h6>
      <router-link :to="{name: 'ViewAnnouncement', params: { 'id': announcement.id}}">View</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Announcement } from "@/store/Announcement";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const announcements = computed(() => store.state.announcements);

    return {
      announcements,
    };
  },
};
</script>

<style scoped>
.announcement-preview {
  border: 1px solid black;
  padding: 0.5rem;
}
</style>
